$block: 'copyright';

.#{$block} {
	text-align: center;
	padding-top: rem-calc(15);

	&__text {
		font-family: $header-font-family;
		color: $white;
		text-shadow: $brand-text-shadow;
	}
}
