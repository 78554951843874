@font-face {
	font-family: 'Geometria';
	src: url('../fonts/body/Geometria.eot');
	src: url('../fonts/body/Geometria.eot?#iefix') format('embedded-opentype'),
	url('../fonts/body/Geometria.woff') format('woff'),
	url('../fonts/body/Geometria.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arista Pro';
	src: url('../fonts/header2/hinted-AristaPro-Thin.eot');
	src: url('../fonts/header2/hinted-AristaPro-Thin.eot?#iefix') format('embedded-opentype'),
	url('../fonts/header2/hinted-AristaPro-Thin.woff2') format('woff2'),
	url('../fonts/header2/hinted-AristaPro-Thin.woff') format('woff'),
	url('../fonts/header2/hinted-AristaPro-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
    font-family: 'Arista Pro';
    src: url('../fonts/header2/hinted-AristaPro-Light.eot');
    src: url('../fonts/header2/hinted-AristaPro-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/header2/hinted-AristaPro-Light.woff2') format('woff2'),
        url('../fonts/header2/hinted-AristaPro-Light.woff') format('woff'),
        url('../fonts/header2/hinted-AristaPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
	font-family: 'Arista Pro';
	src: url('../fonts/header2/hinted-AristaPro-Regular.eot');
	src: url('../fonts/header2/hinted-AristaPro-Regular.eot?#iefix') format('embedded-opentype'),
	url('../fonts/header2/hinted-AristaPro-Regular.woff2') format('woff2'),
	url('../fonts/header2/hinted-AristaPro-Regular.woff') format('woff'),
	url('../fonts/header2/hinted-AristaPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Arista Pro';
	src: url('../fonts/header2/hinted-AristaPro-SemiBold.eot');
	src: url('../fonts/header2/hinted-AristaPro-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('../fonts/header2/hinted-AristaPro-SemiBold.woff2') format('woff2'),
	url('../fonts/header2/hinted-AristaPro-SemiBold.woff') format('woff'),
	url('../fonts/header2/hinted-AristaPro-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
