$block: 'header';

.#{$block} {
	position: absolute;
	right: 0;
	left: 0;
	z-index: 2;
	margin-top: rem-calc(40);

	@include breakpoint(xxmedium) {
		margin-top: rem-calc(50);
	}

	&__box {
		@include xy-grid-container;
		position: relative;
	}

	&__middle {
		background: $color-primary;
		border-radius: rem-calc(17);
		padding: rem-calc(7);
		box-shadow: $brand-green-box-shadow;
	}

	&__row {
		@include xy-grid;
		position: relative;
		z-index: 1;
		background: $color-secondary-lighter;
		background-image: url("../images/noise.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(14);
		box-shadow: $brand-green-box-shadow;
	}

	&__logo {
		@include xy-cell(shrink, false);

		@include breakpoint(xmedium) {
			@include xy-gutters(15, padding, left);
		}

		@include breakpoint(large) {
			@include xy-gutters(30, padding, left);
		}
	}

	&__menu {
		@include xy-cell(auto);
		@include show-for(xxmedium);
	}

	&__menu-button-box {
		@include xy-cell(auto);
		@include hide-for(xxmedium);
	}

	&__menu-button {
		cursor: pointer;
		width: 100%;
		font-size: rem-calc(20);
		font-family: $header-font-family;
		color: $color-primary-darker;
		text-align: center;
		border-left: 1px solid darken($color-secondary-darker, 7);
		border-radius: 0;
		padding: rem-calc(20 0);

		span {
			position: relative;
			padding-left: rem-calc(30);

			&::before {
				@include vertical-center();
				@include icon($icon-menu-1);
				left: rem-calc(-4);
				font-size: rem-calc(26);
			}
		}

	}

	&__bottom {
		position: relative;
	}

	&__lang-switcher {
		@include show-for(medium)
		position: absolute;
		right: rem-calc(240);
		top: rem-calc(-7)
	}

	&__top {
		@include show-for(xmedium);
		padding-left: rem-calc(130);

		@include breakpoint(large) {
			padding-left: rem-calc(250);
		}
	}
}

.header-contacts {
	@include xy-grid();
	@include flex-align(right, middle);
	padding: rem-calc(0 0 15);


	&__column {
		@include xy-cell(shrink);
		text-align: center;

		&--email {
			@include show-for(xxmedium);
		}
	}

	&__link {
		position: relative;
		text-align: center;
		text-transform: lowercase;
		font-family: $header-font-family;
		color: $white;
		font-size: rem-calc(17);
		text-shadow: $brand-text-shadow;
		padding-left: rem-calc(35);

		@include breakpoint(large) {
			font-size: rem-calc(18);
			padding-left: rem-calc(40);
		}

		@include on-event() {
			color: $white;
		}

		&::before {
			@include vertical-center;
			left: 0;
			font-size: rem-calc(26);

			@include breakpoint(medium) {
				font-size: rem-calc(30);
			}
		}

		&--email {
			&::before {
				@include icon($icon-envelope);
			}
		}

		&--phone {
			&::before {
				@include icon($icon-phone);
			}
		}
	}
}
