$block: 'offcanvas-menu';

.#{$block} {
	&__list {
		margin: 0;
		list-style: none;
	}

	&__item {
		position: relative;
		line-height: 1.4;
		padding-left: rem-calc(30);

		&::after {
			@include vertical-center();
			left: 0;
			z-index: 1;
			width: rem-calc(16);
			height: rem-calc(16);
			background: $color-primary;
			border-radius: rem-calc(20);
			content: '';
			box-shadow: $brand-green-box-shadow;
		}

		&::before {
			@include vertical-center();
			left: rem-calc(4);
			z-index: 2;
			width: rem-calc(8);
			height: rem-calc(8);
			background: $color-secondary-lighter;
			background-image: url('https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png'), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
			border-radius: rem-calc(17);
			content: '';
			box-shadow: $brand-green-box-shadow;
		}

		& + & {
			border-top: 1px solid rgba($white, 0.1);
		}
	}

	&__item-link {
		display: block;
		font-size: rem-calc(20);
		font-family: $header-font-family;
		color: $white;
		text-shadow: $brand-text-shadow;
		padding: rem-calc(10 0);

		@include on-event(false) {
			color: $white;
			text-decoration: underline;
			&::before {
				opacity: 1;
			}
		}

		@include on-focus() {
			color: $white;
		}

		&.is-active {
			text-decoration: underline;
		}
	}
}
