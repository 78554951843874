$block: 'breadcrumbs';

.#{$block} {
	z-index: 1;
	float: none !important;
	text-align: center;
	margin: rem-calc(40 0 0);

	&__list {
		margin: 0;
		list-style-type: none;
	}

	&__item {
		display: inline-block;
		// color: $white;

		& + & {
			margin-left: rem-calc(10);
		}
	}

	&__link {
		font-size: rem-calc(13);
		color: rgba($white, 1);
		// font-family: $header-font-family;
		text-shadow: $brand-text-shadow;
		transition: all 300ms ease-in-out;

		@include breakpoint(xxsmall) {
			padding: rem-calc(3 10);
		}

		@include breakpoint(medium) {
			padding: rem-calc(5 10);
		}

		@include on-event {
			color: $white;
		}
	}
}
