$block: 'catalog-menu-offcanvas';

.#{$block} {

	&__box {
		//padding: rem-calc(10 );
	}
	&__item {
		border-top: 1px solid rgba($white, 0.1);
		//padding: rem-calc(8 0);

		&--sub-header {
			background: rgba($black, 0.1);
			padding: rem-calc(5 16);
		}

		& + & {
			//position: relative;
			&::before {
				//content: '';
				//height: 1px;
				//background: rgba($white, 0.3);
				//position: absolute;
				//top: 0;
				//left: rem-calc(10);
				//right: rem-calc(10);
			}
		}
	}

	&__link, &__sub-link, &__section-title {
		color: $white;
		font-family: $header-font-family;
		text-shadow: $brand-text-shadow;
		font-size: rem-calc(20);

		@include on-event() {
			color: $white;
		}
	}

	.js-drilldown-back {
		border-bottom: 1px solid rgba($white, 0.1);
		a {
			color: $white;
			font-family: $header-font-family;
			text-shadow: $brand-text-shadow;
			font-size: rem-calc(20);

			&::before {
				margin-bottom: rem-calc(5);
			}

		}
	}

	&__sub-menu {
		//border-left: 1px solid rgba($white, 0.3);
	}


}

