$block: 'download-catalog';

.#{$block} {
	position: relative;

	&__button {

		font-size: rem-calc(18);
		font-family: $header-font-family;
		color: $white;
		text-transform: uppercase;
		text-shadow: $brand-text-shadow;
		background: $color-orange;
		background-image: radial-gradient(ellipse at top, transparent 60%, rgba($color-orange-darker, 0.8) 100%);
		border-right: 1px solid darken($color-orange, 10);
		border-bottom: 1px solid darken($color-orange, 10);
		border-left: 1px solid darken($color-orange, 10);
		cursor: pointer;
		box-shadow: 0 3px 3px 1px rgba(5, 5, 5, 0.1);
		box-shadow: 0 2px 0 darken(#a77a14, 0), 0 2px 4px rgba($black, 0.3);
		cursor: pointer;

		&--header {
			@include xy-cell(shrink, false);
			@include show-for(medium);
			@include flex;
			@include flex-align(center, middle);
			position: absolute;
			right: rem-calc(40);
			bottom: rem-calc(-28);
			border-radius: rem-calc(0 0 17 17);
			padding: rem-calc(5 20 2);
		}


		&--offcanvas {
			border-radius: rem-calc(10);
			padding: rem-calc(7 20 4);
			text-align: center;
			font-size: rem-calc(20);
		}

		@include on-event {
			span {
				&::before {
					opacity: 1;
				}
			}
		}

		span {
			position: relative;

			&::before {
				position: absolute;
				right: 0;
				bottom: rem-calc(0);
				left: 0;
				height: rem-calc(2);
				background-color: $white;
				border-radius: 2px;
				transition: all 200ms ease-in-out;
				box-shadow: $brand-text-shadow;
				content: '';
				opacity: 0;
			}
		}

	}
}
