$block: 'offcanvas-contacts';

.#{$block} {
	&__block {
		padding: rem-calc(10 0);
		& + & {
			position: relative;
			//padding-top: rem-calc(15);

			&--address {
				//padding-top: rem-calc(10);
			}

			&::before {
				content: '';
				top: 0;
				right: rem-calc(0);
				left: 0;
				height: rem-calc(1);
				background: rgba($white, 0.1);
				position: absolute;
			}
		}
	}

	&__link {
		position: relative;
		font-family: $header-font-family;
		color: $white;
		font-size: rem-calc(20);
		text-shadow: $brand-text-shadow;
		padding-left: rem-calc(35);
		line-height: 1;

		@include on-event() {
			color: $white;
		}

		&::before {
			@include vertical-center;
			left: 0;
			font-size: rem-calc(22);
		}

		&--email {
			&::before {
				@include icon($icon-envelope);
			}
		}

		&--phone {
			&::before {
				@include icon($icon-phone);
			}
		}

		&--address {
			&::before {
				@include icon($icon-home);
			}
		}

	}
}
