$block: 'lang-switcher';

.#{$block} {
	@include xy-grid();
	@include flex();

	&--offcanvas {
		@include flex-align(center, middle) ;
	}

	&--header {
		@include flex-align(center, top);
	}

	&__item {
		@include xy-cell(shrink, false, 0);
		position: relative;
		font-size: rem-calc(16);
		font-family: $header-font-family;
		line-height: 1;
		color: $color-primary-darker;

		&--offcanvas {
			position: relative;
			width: rem-calc(50);
			text-align: center;
			background: $color-secondary-lighter;
			background-image: url('../images/noise.png'), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
			border-radius: rem-calc(17);
			padding: rem-calc(6 10 4);
			//border: rem-calc(2) solid $color-secondary-lighter;
			box-shadow: $brand-green-box-shadow;

			&.is-active {
				border: rem-calc(2) solid darken($color-secondary-darker, 20);
			}
		}

		&--header {
			position: relative;
			line-height: 1;
			font-weight: bold;
			font-family: $header-font-family;
			text-align: center;
			text-transform: uppercase;
			transition: all 300ms ease-in-out;
			text-shadow: $brand-text-shadow;
			background: $color-secondary;
			background-image: url('../images/noise.png'), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
			border-right: 1px solid darken($color-secondary-darker, 10);
			border-bottom: 1px solid darken($color-secondary-darker, 10);
			border-left: 1px solid darken($color-secondary-darker, 10);
			border-radius: rem-calc(0 0 15 15);
			padding: rem-calc(6 15 4);
			cursor: pointer;
			box-shadow: 0 2px 0 darken($color-secondary-darker, 30), 0 2px 4px rgba($black, 0.3);

			@include on-event() {
				line-height: 1.4;
				color: $color-primary-darker;
			}
		}

		& + & {
			margin-left: rem-calc(20);

			&--header {
				margin-left: rem-calc(15);
			}
		}
	}

	&__link {
		position: relative;
		color: $color-primary-darker;

		@include on-event() {
			color: $color-primary-darker;
		}

		&.is-active {
			line-height: 1.4;
		}
	}
}
