$block: 'dashboard';

.#{$block} {
	&__form {
	}

	&__section {
		& + & {
			margin-top: rem-calc(30);
		}
	}

	&__section-header {

		margin-bottom: rem-calc(20);
		// height: rem-calc(90);
		// top: rem-calc(-55);
		// position: relative;
		background: #d8cec7;
		background: $color-primary;
		// border: 1px solid $color-secondary-darker;
		border-radius: rem-calc(20);
		padding: rem-calc(7);
	}

	&__section-header-inner {
		font-family: $header-font-family;
		font-size: rem-calc(18);
		@include xy-grid;
		padding-top: rem-calc(15);
		padding-bottom: rem-calc(15);
		@include flex;
		@include flex-align(right, middle);
		margin: 0;
		// padding: 0;s
		list-style: none;
		background: $color-secondary;
		// border: 4px solid #d8cec7;
		height: 100%;
		border-radius: rem-calc(15);


		box-shadow: 0 0 4px 3px rgba(5,5,5,.1);
		// top: rem-calc(-50);
	}

	&__section-list {
		border: 2px solid $color-secondary-darker;
		padding: rem-calc(15 0);
		border-radius: rem-calc(15);
	}


	&__section-title {
		@include xy-cell(auto);
		font-size: rem-calc(24);
		// color: $color-primary-darker;
	}

	&__section-price {
		@include xy-cell(2);
	}

	&__section-special-price {
		@include xy-cell(2);
	}

	&__section-stock {
		@include xy-cell(1.3);
	}

	&__section-unit {
		@include xy-cell(2);
	}

	&__item {
		@include xy-grid;
		@include flex;
		@include flex-align(right, middle);

		& + & {
			border-top: 1px solid $color-secondary-darker;
			padding-top: rem-calc(10);
			margin-top: rem-calc(10);
		}
	}

	&__item-title {
		@include xy-cell(auto);
		// @include flex-align-self(right);

		& > span {
			font-family: $header-font-family;
			font-size: rem-calc(16);
			// margin-left: rem-calc(10);
		}
	}

	&__item-link {
		font-family: $header-font-family;
		font-size: rem-calc(22);
		display: block;

		& > span {
			position: relative;

			&::before {
				position: absolute;
				content: '';
				bottom: 0;
				width: 100%;
				height: 1px;
				background: darken($color-secondary-darker, 10);
			}

		}



	}

	&__item-price {
		@include xy-cell(2);
		margin-bottom: 0;
	}

	&__item-special-price {
		@include xy-cell(2);
		margin-bottom: 0;
	}

	&__item-unit {
		@include xy-cell(2);
		margin-bottom: 0;
	}

	&__item-stock {
		@include xy-cell(1.3);
		margin-bottom: 0;
	}

	&__button {
		display: block;
		margin-top: rem-calc(20);
		// height: rem-calc(90);
		// top: rem-calc(-55);
		// position: relative;
		background: #d8cec7;
		background: $color-primary;
		// border: 1px solid $color-secondary-darker;
		border-radius: rem-calc(20);
		padding: rem-calc(7);
		cursor: pointer;

		@include on-event {
			background: $color-primary-darker;
		}

		span {
			display: block;
			font-family: $header-font-family;
			padding: rem-calc(15 30);
			margin: 0;
			color: $body-font-color;


			// padding: 0;s
			list-style: none;
			background: $color-secondary;
			// border: 4px solid #d8cec7;
			height: 100%;
			border-radius: rem-calc(15);


			box-shadow: 0 0 4px 3px rgba(5,5,5,.1);
		}
	}
}
