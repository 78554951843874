$block: 'bottom-menu';

.#{$block} {
	@include show-for(large);
	background: $color-primary;
	border-radius: rem-calc(17);
	padding: rem-calc(7);
	box-shadow: $brand-green-box-shadow;
	padding: rem-calc(7);

	&__list {

		@include xy-grid;

		padding-top: rem-calc(15);
		padding-bottom: rem-calc(15);
		height: 100%;
		margin: 0;
		list-style: none;
		background: $color-secondary-lighter;
		background-image: url("../images/noise.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(14);
		box-shadow: $brand-green-box-shadow;

		@include breakpoint(xxmedium) {
			@include flex-align(center);
		}
	}

	&__item {
		@include xy-cell(full);

		position: relative;
		text-align: center;
		padding: rem-calc(5 0);


		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		@include breakpoint(xmedium) {
			@include xy-cell(4);
		}

		@include breakpoint(xxmedium) {
			@include xy-cell(shrink);
		}

		@include breakpoint(large) {
			padding: rem-calc(5 10);
		}

		& + & {
			&::before {
				@include breakpoint(xxsmall down) {
					position: absolute;
					top: rem-calc(-4);
					right: 0;
					left: 0;
					height: rem-calc(1);
					background: darken($color-secondary-darker, 3);
					content: '';
				}

				@include breakpoint(large) {
					position: absolute;
					top: rem-calc(10);
					bottom: rem-calc(10);
					left: rem-calc(-15);
					width: rem-calc(1);
					background: darken($color-secondary-darker, 3);
					content: '';
				}
			}
		}
	}

	&__item-link {
		position: relative;
		//display: block;
		font-size: rem-calc(18);
		font-family: $header-font-family;
		// text-transform: uppercase;
		color: $color-primary-darker;
		text-align: center;
		transition: all 300ms ease-in-out;

		@include on-event {
			color: $color-primary-darker;
			text-decoration: underline;
		}

		&.is-active {
			//&::before {
			//	content: '';
			//	height: rem-calc(2);
			//	background: red;
			//	position: absolute;
			//	width: 100%;
			//	bottom: rem-calc(-4);
			//}
		}
	}
}
