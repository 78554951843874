$block: 'c-catalog-menu';

.#{$block} {
	@include xy-grid-container();
	position: relative;
	z-index: 2;
	bottom: rem-calc(-50);

	@include breakpoint(medium) {
		@include xy-gutters($gutter-type: padding);
	}

	&__box {
		background: $color-primary;
		z-index: 2;
		position: relative;
		border-radius: rem-calc(20);
		padding: rem-calc(7);
		box-shadow: $brand-green-box-shadow;
	}

	&__box-inner {
		height: 100%;
		background: $color-secondary-lighter;
		background-image: url("../images/noise.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(17);
		box-shadow: $brand-green-box-shadow;
	}

	&__list {
		@include show-for(large);
		@include xy-grid;
		padding: rem-calc(0 10);
		margin: 0;
		list-style: none;
	}

	&__button-box {
		@include hide-for(large);
		text-align: center;
	}

	&__button {
		font-family: $header-font-family;
		font-size: rem-calc(20);
		padding: rem-calc(20);
		width: 100%;
		cursor: pointer;
	}

	&__item {
		position: relative;
		@include xy-cell(auto, false, 0);

		& + & {
			padding-left: rem-calc(10);

			&::before {
				content: '';
				@include vertical-center;
				height: rem-calc(50);
				width: rem-calc(1);
				background: #d8cec7;
			}
		}

		&.is-active + & {
			&::before {
				display: none;
			}
		}
	}

	&__item-link {
		position: relative;
		text-align: center;
		width: 100%;
		font-size: rem-calc(22);
		font-family: $header-font-family;
		color: $color-primary-darker;
		display: block;
		padding: rem-calc(55 15 15);

		&::before {
			position: absolute;
			width: 100%;
			border-radius: 2px;
			background-color: $white;
			box-shadow: $brand-text-shadow;
			content: '';
			height: 0;
			left: 0;
			right: 0;
			background: #7da744;
			background-image: radial-gradient(ellipse  at top, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
			border-radius: rem-calc(15);
			padding: rem-calc(0 15);
			box-shadow: 0 2px 0 darken(#648e37, 15), 0 2px 4px rgba($black, 0.3), 0 0 4px 3px rgba(5,5,5,.1), 0 1px 3px rgba(30, 50, 70, 0.3), inset 0 0 1px rgba(255, 255, 255, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.12);
			opacity: 0;
		}

		&::after {
			@include horizontal-center;
			top: rem-calc(20);
			font-size: rem-calc(34);
		}

		@include on-event {
			color: $white;
			text-shadow: $brand-text-shadow;
			&::before {
				opacity: 1;
				top: rem-calc(10);
				bottom: rem-calc(10);
				height: auto;
				border-radius: rem-calc(12);
			}

			span {
				position: relative;

				&::before {
					height: rem-calc(2);
				}
			}
		}

		&.is-active {
			// padding: rem-calc(33 15 25);
			color: $white;
			text-shadow: $brand-text-shadow;

			&::before {
				opacity: 1;
				opacity: 1;
				top: rem-calc(10);
				bottom: rem-calc(10);
				top: rem-calc(-10);
				bottom: rem-calc(-8);
				height: auto;
			}

			&::after {
				top: rem-calc(10);
				font-size: rem-calc(40);

			}

			span {
				position: relative;

				&::before {
					position: absolute;
					width: 100%;
					height: 2px;
					border-radius: 2px;
					background-color: $white;
					box-shadow: $brand-text-shadow;
					content: '';
					bottom: 0;
				}
			}
		}

		&--vegetables {
			&::after {
				@include icon($icon-tomato);
			}
		}

		&--fruits {
			&::after {
				@include icon($icon-banana-1);
			}
		}

		&--nuts {
			&::after {
				@include icon($icon-peanut);
			}
		}

		&--berries {
			&::after {
				@include icon($icon-raspberry);
			}
		}

		&--honey {
			&::after {
				@include icon($icon-honey-2);
			}
		}

		&--greenery {
			&::after {
				@include icon($icon-parsley);
			}
		}

		span {
			position: relative;
			z-index: 1;
						white-space: nowrap;

			&::before {
				transition: all 200ms ease-in-out;
				position: absolute;
				width: 100%;
				height: 0;
				border-radius: 2px;
				background-color: $white;
				box-shadow: $brand-text-shadow;
				content: '';
				bottom: 0;
			}
		}
	}
}

.catalog-sidebar-menu {
	//background: #d8cec7;
	//background: $color-primary;
	// border: 1px solid $color-secondary-darker;
	//border-radius: rem-calc(11);
	//padding: rem-calc(7);

	&__box {
		// @include xy-grid;
		//padding: rem-calc(0 10);
		// @include flex;
		// @include flex-align(justify);
		margin: 0;
		// padding: 0;s
		list-style: none;
		//background: $color-secondary;
		// border: 4px solid #d8cec7;
		height: 100%;
		border-radius: rem-calc(9);


		//box-shadow: 0 0 4px 3px rgba(5,5,5,.1);
	}

	&__item {
		& + & {
			margin-top: rem-calc(20);
			position: relative;

			&::before {
				content: '';
				position: absolute;
				height: rem-calc(1);
				width: 100%;
				left: 0;
				top: rem-calc(-11);
				background: #d8cec7;


			}
		}
	}

	&__link {
		font-family: $header-font-family;
		font-size: rem-calc(19);
		line-height: 1;
		color: $color-primary-darker;
		position: relative;

		@include on-event {
			color: $color-primary-darker;
			&::before {
				opacity: 1;
			}
		}

		&::before {
			content: '';
			position: absolute;
			bottom: rem-calc(0);
			height: rem-calc(2);
			border-radius: 1px;
			width: 100%;
			background: $color-primary-darker;
			opacity: 0;
			transition: all 300ms ease-in-out;
		}

		&.is-active {
			&::before {
				opacity: 1;
			}
		}
	}

	&__list {
		//padding: rem-calc(20 10);
		list-style: none;
		margin: 0;
	}
}

//.test {
//	background: $color-secondary;
//	// border: 1px solid $color-secondary-darker;
//	border-radius: rem-calc(0 0 20 20);
//	padding: rem-calc(20);
//	color: $white;
//	text-shadow: $brand-text-shadow;
//	font-family: $header-font-family;
//	font-size: rem-calc(24);
//	margin: rem-calc(0 20);
//	background-image: radial-gradient(ellipse at center, transparent 60%, rgba(84, 113, 46, 0.8) 100%);
//	box-shadow:  0 0 4px 3px rgba(5,5,5,.1), 0 1px 3px rgba(30, 50, 70, 0.3), inset 0 0 1px rgba(255, 255, 255, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.12);
//}
