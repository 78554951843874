$block: 'top-menu';

.#{$block} {
	&__list {
		list-style: none;
		margin: 0;
		@include xy-grid;
		@include xy-gutters($negative: true);
		@include flex-align(right);
	}

	&__item {
		position: relative;
		line-height: 1.4;
		padding: rem-calc(24 15 20);
		@include xy-cell(shrink, false);

		@include breakpoint(xlarge) {
			padding: rem-calc(24 30);

		}

		& + & {
			&::before {
				content: '';
				top: rem-calc(30);
				bottom: rem-calc(30);
				left: 0;
				width: rem-calc(1);
				background: darken($color-secondary-darker, 3);
				position: absolute;
			}
		}
	}

	&__item-link {
		position: relative;
		display: block;
		color: $color-primary-darker;
		// text-align: center;
		white-space: nowrap;
		// text-transform: uppercase;
		font-family: $header-font-family;
		font-size: rem-calc(20);
		// font-size: rem-calc(14);
		// font-weight: 300;

		&::before {
			content: '';
			transition: all 300ms ease-in-out;
			height: rem-calc(2);
			background: $color-primary-darker;
			position: absolute;
			left: 0;
			width: 100%;
			bottom: rem-calc(0);
			opacity: 0;
		}

		@include on-event {
			color: $color-primary-darker;
			&::before {
				opacity: 1;
			}
		}

		&.is-active {
			&::before {
				opacity: 1;
			}
		}
	}
}
