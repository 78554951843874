$icomoon-font-path: "../fonts/iconmoon" !default;
$icomoon-font-family: "icomoon" !default;

@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?dtxpgj');
  src:  url('#{$icomoon-font-path}/icomoon.eot?dtxpgj#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.ttf?dtxpgj') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?dtxpgj') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?dtxpgj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: '#{$icomoon-font-family}' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

$icon-phone: "\e912";
$icon-phone-1: "\e913";
$icon-urban1: "\e915";
$icon-envelope: "\e916";
$icon-home: "\e917";
$icon-parsley: "\e900";
$icon-banana-1: "\e901";
$icon-raspberry: "\e902";
$icon-peanut: "\e903";
$icon-tomato: "\e904";
$icon-honey-2: "\e905";
$icon-list: "\e906";
$icon-menu: "\e907";
$icon-checked: "\e908";
$icon-next: "\e909";
$icon-cancel: "\e90a";
$icon-cancel-1: "\e90b";
$icon-download: "\e90c";
$icon-download-1: "\e90d";
$icon-checked-1: "\e90e";
$icon-menu-1: "\e90f";
$icon-appointment: "\e910";
$icon-loupe: "\e911";
$icon-urban: "\e914";

.icon-phone {
	&:before {
		content: $icon-phone;
	}
}
.icon-phone-1 {
	&:before {
		content: $icon-phone-1;
	}
}
.icon-urban1 {
	&:before {
		content: $icon-urban1;
	}
}
.icon-envelope {
	&:before {
		content: $icon-envelope;
	}
}
.icon-home {
	&:before {
		content: $icon-home;
	}
}
.icon-parsley {
	&:before {
		content: $icon-parsley;
	}
}
.icon-banana-1 {
	&:before {
		content: $icon-banana-1;
	}
}
.icon-raspberry {
	&:before {
		content: $icon-raspberry;
	}
}
.icon-peanut {
	&:before {
		content: $icon-peanut;
	}
}
.icon-tomato {
	&:before {
		content: $icon-tomato;
	}
}
.icon-honey-2 {
	&:before {
		content: $icon-honey-2;
	}
}
.icon-list {
	&:before {
		content: $icon-list;
	}
}
.icon-menu {
	&:before {
		content: $icon-menu;
	}
}
.icon-checked {
	&:before {
		content: $icon-checked;
	}
}
.icon-next {
	&:before {
		content: $icon-next;
	}
}
.icon-cancel {
	&:before {
		content: $icon-cancel;
	}
}
.icon-cancel-1 {
	&:before {
		content: $icon-cancel-1;
	}
}
.icon-download {
	&:before {
		content: $icon-download;
	}
}
.icon-download-1 {
	&:before {
		content: $icon-download-1;
	}
}
.icon-checked-1 {
	&:before {
		content: $icon-checked-1;
	}
}
.icon-menu-1 {
	&:before {
		content: $icon-menu-1;
	}
}
.icon-appointment {
	&:before {
		content: $icon-appointment;
	}
}
.icon-loupe {
	&:before {
		content: $icon-loupe;
	}
}
.icon-urban {
	&:before {
		content: $icon-urban;
	}
}


