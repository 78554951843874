$block: 'catalog';

.#{$block} {
	margin: rem-calc(-60 30 0);
	background: $color-primary;
	position: relative;
	border-radius: rem-calc(0 0 20 20);
	padding: rem-calc(0 7 7);
	box-shadow: $brand-green-box-shadow;

	&__row {
		@include xy-grid;
		background: $color-secondary-lighter;
		background-image: url("../images/noise.png"), radial-gradient(ellipse at center, transparent 95%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(0 0 17 17);
		box-shadow: $brand-green-box-shadow;
	}

	&__content {
		@include xy-cell(auto);
		//background: $color-primary;
				// background-image: radial-gradient(ellipse  at center, transparent 40%, rgba($color-primary-darker, 0.8) 100%);
		//border-radius: rem-calc(0 0 15 15);
		//padding: rem-calc(50 7 7);
		@include flex-align-self(top);

		@include breakpoint(medium) {
			@include xy-cell(auto);
			//margin: 0;
		}
	}


	&__list {
		//border-radius: rem-calc(11);
		//background: $color-secondary-lighter;
		//background-image: url("https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png"), radial-gradient(ellipse at center, transparent 60%, $color-secondary-lighter 100%);
		////border-radius: rem-calc(17);
		//box-shadow: $brand-green-box-shadow;
		//border-left: 1px solid red;
		overflow: hidden;
		@include xy-grid;
		@include xy-grid-layout(2, '.c-product-intro', false, 0);

		@include breakpoint(medium) {
			@include xy-grid-layout(3, '.c-product-intro', false, 0);
		}

		@include breakpoint(xxmedium) {
			@include xy-grid-layout(4, '.c-product-intro', false, 0);
		}

		@include breakpoint(large) {
			@include xy-grid-layout(1, '.c-product-intro', false, 0);
		}
	}

	&__sidebar {
		@include breakpoint(xxmedium down) {
			@include xy-cell(12);
		}

		@include breakpoint(large) {
			@include xy-cell(3.3);
			height: 100%;

		}
	}

	&__sidebar-menu {
		@include show-for(large);
	}


	&__desc {
		margin-top: rem-calc(30);
	}

	&__pagination {

	}

	&__empty-message {
		@include xy-cell(full);
		padding-top: rem-calc(30);
		padding-bottom: rem-calc(30);
		color: $color-primary-darker;
		text-align: center;
		font-family: $header-font-family;
		font-size: rem-calc(20);
	}
}
