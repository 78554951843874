$block: 'offcanvas';

.#{$block} {
	&__close-button {
		position: relative;
		color: $white;
		text-shadow: $brand-text-shadow;
		font-size: rem-calc(20);
		padding: rem-calc(8 16);
		font-family: $header-font-family;
		cursor: pointer;
		display: block;
		background: rgba($black, 0.1);

		&::before {
			@include icon($icon-cancel-1);
			@include vertical-center();
			color: $white;
			text-shadow: $brand-text-shadow;
			right: rem-calc(15);
		}
	}

	&__block {
		padding: rem-calc(0 15);

		&--contacts {
			//position: relative;
			//
			//&::before {
			//	content: '';
			//	position: absolute;
			//	height: rem-calc(1);
			//	top: 0;
			//	right: rem-calc(15);
			//	left: rem-calc(15);
			//	background: rgba($white, 0.1);
			//}
		}

		& + & {
			padding-top: rem-calc(25);

			&--download-catalog {
				padding-top: rem-calc(15);
			}
		}
	}
}
