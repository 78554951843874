$block: 'container';

.#{$block} {
	// @include xy-grid;

	&--footer {
		// background: $color-primary;
		// background-image: url('/site/templates/assets/images/bg-footer.jpg') 0 0 repeat;
		// color: $color-primary-lighter;
		// padding: rem-calc(30 0);
		position: relative;
		//z-index: 2;
		background: $color-primary;
		background-image: url('/site/templates/assets/images/footer.jpg');
		// background-size: contain;
		background-position: 0 0;
		background-repeat: repeat;
		// padding: rem-calc(200 0 60);


		&::before {
			position: absolute;
			top: 0;
			bottom: 0;
			width: 100%;
			// height: rem-calc(300);
			background: linear-gradient(to bottom, rgba($black, 0.2), rgba($white, 0.3));
			background: linear-gradient(to top, rgba($color-primary-darker, 0.6), rgba($color-primary, 0.2));
			z-index: 1;
			content: '';
		}

		&::after {
			position: absolute;
			bottom: 0;
			top: 0;
			background: rgba($color-primary, 0.6);
			background: rgba($black, 0.3);
			width: 100%;
			// height: rem-calc(80);
			// background-image: url('/site/templates/assets/images/waves.svg');
			// background-position: 50% 0;
			// background-size: contain;
			// background-size: cover;
			content: '';
			// background-repeat: repeat-x;
		}

		.#{$block}__wrap {
			position: relative;
			z-index: 2;
			//@include xy-gutters(100, padding, top);
			@include xy-gutters(60, padding, bottom);

		}

	}

	&__wrap {
		@include xy-grid-container;
		// @include xy-cell(12);
		@include xy-gutters(100, padding, top bottom);
	}
}
