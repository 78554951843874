$block: 'c-catalog-menu-2';

.#{$block} {
	@include xy-grid-container;
	@include horizontal-center;
	z-index: 1;
	// top: rem-calc(-210);
	width: 100%;

	&__list {
		@include xy-grid;
		@include flex;
		@include flex-align(right);
		@include xy-gutters(60, padding, right);
		position: relative;
		z-index: 3;
		margin: 0;
		padding-left: 0;
		list-style: none;
	}

	&__item {
		cursor: pointer;

		& + & {
			margin-left: rem-calc(15);
		}

		.#{$block}__item-dropdown {
			background: $color-pink;
			width: rem-calc(700);
			border-radius: rem-calc(20);
			border-color: $color-pink;
			box-shadow: 0 2px 0 $color-pink-darker;
		}

		&--mint {
			.#{$block}__item-link {
				// background: $color-mint;
				// box-shadow: 0 2px 0 $color-mint-darker, 0 2px 3px rgba($black, 0.2) inset;
			}

			.#{$block}__item-dropdown {
				background: $color-mint;
				border-color: $color-mint;
				box-shadow: 0 2px 0 $color-mint-darker;
			}
		}

		&--blue {
			// .#{$block}__item-link {
			// 	background: $color-blue;
			// 	box-shadow: 0 2px 0 $color-blue-darker, 0 2px 3px rgba($black, 0.2) inset;
			// }

			.#{$block}__item-dropdown {
				background: $color-blue;
				border-color: $color-blue;
				box-shadow: 0 2px 0 $color-blue-darker;
			}
		}
	}

	&__item-link {
		position: relative;
		font-size: rem-calc(20);
		font-family: $header-font-family;
		color: $white;
		display: inline-block;
		text-shadow: $brand-text-shadow;
		// background: #d8cec7;
		// background: lighten(#648e37, 5);
		// background: #7da744;
		// background-image: radial-gradient(ellipse  at top, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
		// background: #90bf2a;
		// background: $color-primary-darker;
		// border: 1px solid red;
		// border-radius: rem-calc(0 0 15 15);
		padding: rem-calc(0 15);
		// box-shadow: 0 2px 0 darken(#648e37, 15), 0 2px 4px rgba($black, 0.3);
		// transition: all 300ms ease-in-out;

		&::before {
			position: absolute;
			transition: all 200ms ease-in-out;
			width: 100%;
			height: rem-calc(32);
			border-radius: 2px;
			background-color: $white;
			box-shadow: $brand-text-shadow;
			content: '';
			top: 0;
			left: 0;
			background: #7da744;
			background-image: radial-gradient(ellipse  at top, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
			// background: #90bf2a;
			// background: $color-primary-darker;
			// border: 1px solid red;
			border-radius: rem-calc(0 0 15 15);
			padding: rem-calc(0 15);
			box-shadow: 0 2px 0 darken(#648e37, 15), 0 2px 4px rgba($black, 0.3);
		}


		@include on-event {
			color: $white;
			&::before {
				height: rem-calc(35);
			}


			span {
				position: relative;

				&::before {
					height: rem-calc(2);
				}
			}
		}

		&.is-active {
			&::before {
				height: rem-calc(35);
			}

			span {
				position: relative;

				&::before {
					position: absolute;
					width: 100%;
					height: 2px;
					border-radius: 2px;
					background-color: $white;
					box-shadow: $brand-text-shadow;
					content: '';
					bottom: 0;
				}
			}
		}

		span {
			position: relative;
			z-index: 1;

			&::before {
				transition: all 200ms ease-in-out;
				position: absolute;
				width: 100%;
				height: 0;
				border-radius: 2px;
				background-color: $white;
				box-shadow: $brand-text-shadow;
				content: '';
				bottom: 0;
			}
		}
	}
}
