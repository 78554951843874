$block: 'main';

.#{$block} {

	position: relative;
	// z-index: 1;
	 box-shadow: 0 0 15px 15px rgba($black, 0.3);
	//margin-bottom: rem-calc(40);


	&::before {
		position: absolute;
		top: rem-calc(-17);
		z-index: 1;
		width: 100%;
		height: rem-calc(24);
		background-image: url('/site/templates/assets/images/pattern.svg');
		background-position: 0 0;
		//background-size: 160px 20px;
		// background-size: contain;
		// background-size: cover;
		content: '';
		background-repeat: repeat-x;
	}

	&::after {
		position: absolute;
		bottom: rem-calc(-17);
		z-index: 2;
		width: 100%;
		height: rem-calc(24);
		background-image: url('/site/templates/assets/images/pattern-bottom.svg');
		background-position: 0 0;
		//background-size: 160px 20px;
		// background-size: contain;
		// background-size: cover;
		content: '';
		background-repeat: repeat-x;
	}

	&__box {
		// @include xy-grid;
		@include xy-grid-container;
		padding-top: rem-calc(100);
		padding-bottom: rem-calc(50);

		@include breakpoint(medium) {
			padding-top: rem-calc(110);
			padding-bottom: rem-calc(60);
		}
	}
}
