$block: 'masthead';

.#{$block} {
	position: relative;
	background: $color-primary-lighter;
	// background-image: url('/site/templates/assets/images/bg-style-1.png');
	background-image: url('/site/templates/assets/images/masthead-vegatables.jpg');
	background-size: cover;
	background-position: 50% 0%;
	padding: rem-calc(170 0 0);
	background-repeat: no-repeat;
	// padding: rem-calc(140 0 80);

	@include breakpoint(xmedium) {
		padding: rem-calc(230 0 0);
	}

	@include breakpoint(large) {
		padding: rem-calc(250 0 0);
	}

	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		// height: rem-calc(300);
		background: linear-gradient(to bottom, rgba($black, 0.2), rgba($white, 0.3));
		background: linear-gradient(to bottom, rgba($color-primary-darker, 0.3), rgba($color-primary, 0.2));
		z-index: 1;
		content: '';
	}

	&::after {
		position: absolute;
		bottom: 0;
		top: 0;
		background: rgba($color-primary, 0.6);
		background: rgba($black, 0.5);
		width: 100%;
		// height: rem-calc(80);
		// background-image: url('/site/templates/assets/images/waves.svg');
		// background-position: 50% 0;
		// background-size: contain;
		// background-size: cover;
		content: '';
		// background-repeat: repeat-x;
	}
	//
	// &::after {
	// 	position: absolute;
	// 	bottom: 0;
	// 	z-index: 1;
	// 	width: 100%;
	// 	height: rem-calc(80);
	// 	background-image: url('/site/templates/assets/images/waves.svg');
	// 	background-position: 50% 0;
	// 	// background-size: contain;
	// 	background-size: cover;
	// 	content: '';
	// 	background-repeat: repeat-x;
	// }

	&__box {
		@include xy-grid-container;
		position: relative;
		z-index: 1;
		color: $white;
		text-align: center;
	}

	&__title {
		color: $white;
		text-shadow: $brand-text-shadow;
		font-size: rem-calc(22);

		@include breakpoint(medium) {
			font-size: rem-calc(26);
		}

		@include breakpoint(xmedium) {
			font-size: rem-calc(34);
		}

		span {
			position: relative;
			border-bottom: 3px solid $white;

			// &::after {
			// 	@include horizontal-center;
			// 	bottom: rem-calc(-2);
			// 	width: 100%;
			// 	height: rem-calc(3);
			// 	background: $white;
			// 	border-radius: rem-calc(3);
			// 	transition: all 300ms ease-in-out;
			// 	box-shadow: $brand-text-shadow;
			// 	content: '';
			// }
		}

	}

	&__sub-title {
		font-size: rem-calc(20);
		text-shadow: $brand-text-shadow;
		font-weight: 300;
		margin: rem-calc(10 25 0);

		@include breakpoint(medium) {
			font-size: rem-calc(22);
			margin: rem-calc(10 30 0);
		}

		@include breakpoint(xmedium) {
			font-size: rem-calc(24);
			margin: rem-calc(10 40 0);
		}


	}

	&__catalog-menu {
		position: relative;
		z-index: 4;
		// position: absolute;
		// bottom: 0;
	}
}
