$block: 'c-product';

.#{$block} {
	@include xy-grid;
	@include xy-gutters($negative: true);

	&__images-box {
		@include xy-cell(12);

		@include breakpoint(xxmedium) {
			@include xy-cell(5);
			@include xy-gutters(60, padding, right);

		}
	}

	&__info-box {
		@include xy-cell(7);
		@include xy-gutters(60, padding, left);
	}

	&__image {
		border: 2px solid $medium-gray;
		border-radius: rem-calc(30);
		padding: rem-calc(20);
	}

	&__price-box {
		padding: rem-calc(15 0);
		// @include clearfix;
		// line-height: rem-calc(40);
		// vertical-align: middle;
	}

	&__price {
		font-size: rem-calc(50);
		font-weight: bold;
		font-family: $header-font-family;
		line-height: rem-calc(50);
		// float: left;
		// display: inline-block;
		color: $color-primary;
	}

	&__old-price {
		font-size: rem-calc(30);
		font-weight: bold;
		font-family: $header-font-family;
		line-height: rem-calc(50);
		// display: inline-block;
		color: rgba($color-primary, 0.8);
		text-decoration: line-through;
		margin-left: rem-calc(20);
	}

	&__labels {
		@include clearfix;
		margin-bottom: rem-calc(20);
	}
}
