$block: 'footer';

.#{$block} {

	//@include xy-grid;
	//@include xy-gutters($negative: true);
	//
	//&__column {
	//	@include xy-cell(4);
	//}
}

.footer-contacts {
	@include xy-grid();
	@include xy-gutters(14, $negative: true);
	padding: rem-calc(0 0 15);

	@include breakpoint(large) {
		padding-bottom: rem-calc(30);
	}

	&__column {
		@include xy-cell(12, true, 14);
		text-align: center;

		@include breakpoint(xmedium) {
			@include xy-cell(4);
		}

		&--phone {
			@include breakpoint(medium) {
				@include xy-cell(6, true, 14);
			}

			@include breakpoint(xxmedium) {
				@include xy-cell(3.5, true, 14);
			}
		}

		&--email {
			@include breakpoint(medium) {
				@include xy-cell(12, true, 14);
			}

			@include breakpoint(xxmedium) {
				padding-top: 0;
				@include xy-cell(5, true, 14);
			}
		}

		& + & {
			padding-top: rem-calc(7);

			@include breakpoint(medium) {
				padding-top: 0;
			}

			&--email {
				@include breakpoint(medium) {
					padding-top: rem-calc(7);
				}

				@include breakpoint(xxmedium) {
					padding-top: 0;
				}
			}
			//padding-left: rem-calc(20);


		}
	}

	&__column-inner	{
		//background: $color-secondary;
		//border-radius: rem-calc(15);
		//padding: rem-calc(5 10);
		//
		//
		//box-shadow: 0 0 4px 3px rgba(5, 5, 5, .1);
	}

	&__link {
		position: relative;
		text-align: center;
		font-family: $header-font-family;
		color: $white;
		font-size: rem-calc(20);
		text-shadow: $brand-text-shadow;
		padding-left: rem-calc(35);

		@include breakpoint(medium) {
			font-size: rem-calc(24);
			padding-left: rem-calc(40);
		}

		@include on-event() {
			color: $white;
		}

		&::before {
			@include vertical-center;
			left: 0;
			font-size: rem-calc(26);

			@include breakpoint(medium) {
				font-size: rem-calc(30);
			}
		}

		&--email {
			&::before {
				@include icon($icon-envelope);
			}
		}

		&--phone {
			&::before {
				@include icon($icon-phone);
			}
		}

		&--address {
			&::before {
				@include icon($icon-home);
			}
		}
	}
}
