@function unicode($str) {
  @return unquote("\"") + $str + unquote("\"");
}


@mixin icon($icon) {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-decoration: inherit;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: unicode($icon);
}

@mixin on-event($focus: true) {
	@if $focus {
		&:hover, &:active, &:focus {
			@content;
		}
	}

	@else {
		&:hover, &:active {
			@content;
		}
	}
}

@mixin on-focus($self: false) {
	@if $self {
		&, &:focus {
			@content;
		}
	}

	@else {
		&:focus {
			@content;
		}
	}
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
	#{$context} & {
		@content;
	}
}

@mixin mx-header-size($type) {
	font-size: rem-calc(map-get(map-get(map-get($header-styles, 'small'), $type), 'font-size') * 1px);

	@include breakpoint(medium) {
		font-size: rem-calc(map-get(map-get(map-get($header-styles, 'medium'), $type), 'font-size') * 1px);
	}

	// @include breakpoint(large) {
	// 	font-size: rem-calc(map-get(map-get(map-get($header-styles, 'large'), $type), 'font-size') * 1px);
	// }

	// @include breakpoint(large) {
	// 	font-size: rem-calc(map-get(map-get(map-get($header-styles, 'large'), $type), 'font-size') * 1px);
	// }
}
