$block: 'c-label';

.#{$block} {
	@include xy-cell(shrink, false, 0);


	& + & {
		margin-left: rem-calc(10);
	}

	&__value {
		display: inline-block;
		font-size: rem-calc(14);
		border-radius: rem-calc(5);
		padding: rem-calc(2 10);
		color: $white;
		text-shadow: $brand-text-shadow;
		line-height: 1;

		@include breakpoint(xmedium) {
			padding: rem-calc(5 15);
		}

		&--hit {
			background: $color-purple-lighter;
			box-shadow: 0 2px 0 $color-purple;
		}

		&--new {
			background: $color-mint;
			box-shadow: 0 2px 0 $color-mint-darker;
		}

		&--sale {
			background: $color-pink;
			box-shadow: 0 2px 0 $color-pink-darker;
		}

		&--product-intro {
				padding: rem-calc(5 10 4);
		}
	}
}
