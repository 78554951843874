$block: 'c-catalog-menu-nested';

.#{$block} {
	&__list {
		@include xy-grid;
		@include xy-gutters($negative: true);
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item {
		@include xy-cell(4);
	}

	&__item-link {
		display: block;
		font-size: rem-calc(20);
		font-family: $header-font-family;
		color: $white;
		text-align: center;
		text-shadow: $brand-text-shadow;
		background: $color-mint-darker;
		border-radius: rem-calc(10);
		margin-bottom: rem-calc(10);
		padding: rem-calc(3 15);

		@include on-event {
			color: $white;

			span {
				&::after {
					opacity: 1;
				}
			}
		}

		span {
			position: relative;

			&::after {
				@include horizontal-center;
				bottom: rem-calc(-2);
				width: 100%;
				height: rem-calc(2);
				background: $white;
				border-radius: rem-calc(3);
				transition: all 300ms ease-in-out;
				content: '';
				opacity: 0;
				box-shadow: $brand-text-shadow;
			}
		}
	}

	&-nested {
		&__sub-list {
			position: relative;
			margin: 0;
			padding: 0;
			list-style: none;
		}

		&__item {
			& + & {
				margin-top: rem-calc(3);
			}
		}

		&__item-link {
			position: relative;
			font-size: rem-calc(20);
			font-family: $header-font-family;
			color: $color-primary;
			padding-left: rem-calc(20);

			@include on-event {
				span {
					&::after {
						opacity: 1;
					}
				}
			}

			&::before {
				@include vertical-center;
				left: rem-calc(5);
				width: rem-calc(6);
				height: rem-calc(6);
				background: rgba($white, 1);
				border-radius: 100%;
				box-shadow: $brand-text-shadow;
				content: '';
			}

			span {
				position: relative;

				&::after {
					@include horizontal-center;
					bottom: rem-calc(-2);
					width: 100%;
					height: rem-calc(2);
					background: $color-primary;
					border-radius: rem-calc(3);
					transition: all 300ms ease-in-out;
					content: '';
					opacity: 0;
				}
			}
		}
	}
}
