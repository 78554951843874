$block: 'c-product-intro';

.#{$block} {
	position: relative;
	padding: rem-calc(7);

	@include breakpoint(medium) {
		padding: rem-calc(10);
		//border-top: 1px solid #d8cec7;
	}

	@include on-event {
		background: rgba($color-secondary-darker, 0.4);
		background-image: radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
	}

	&:last-child,
	&:nth-last-child(2):nth-child(odd) {
		border-right: 1px solid #d8cec7;
		//border-right: 1px solid green;
	}

	&:nth-child(1n)	{
		@include breakpoint(xxsmall down) {
			border-right: 1px solid #d8cec7;
		}
	}

	&:nth-child(3n + 1),
	&:nth-child(3n + 2){
		@include breakpoint(medium only) {
			border-right: 1px solid #d8cec7;
		}

		@include breakpoint(xmedium only) {
			border-right: 1px solid #d8cec7;
		}
	}

	&:nth-child(4n + 1),
	&:nth-child(4n + 2),
	&:nth-child(4n + 3) {
		@include breakpoint(xxmedium only) {
			border-right: 1px solid #d8cec7;
		}
	}

	&:nth-child(n+3) {
		@include breakpoint(xxsmall down) {
			border-top: 1px solid #d8cec7;
		}
	}

	&:nth-child(n+4) {
		@media screen and #{breakpoint(medium)} and #{breakpoint(xmedium down)} {
			border-top: 1px solid #d8cec7;

		}
	}
	&:nth-child(n+5) {
		@include breakpoint(xxmedium only) {
			border-top: 1px solid #d8cec7;
		}
	}

	&:nth-last-child(2):nth-child(even) {
		@include breakpoint(xxmedium down) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}

	&:nth-last-child(2):nth-child(odd) {
		@include breakpoint(xxmedium down) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}
	&:nth-last-child(3):nth-child(odd) {
		@include breakpoint(xxmedium down) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}

	&:nth-last-child(4):nth-child(odd) {
		@include breakpoint(xxmedium only) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}
	&:nth-last-child(4):nth-child(even) {
		@include breakpoint(xxmedium only) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}

	&:nth-last-child(3):nth-child(even) {
		@include breakpoint(xxmedium down) {
			border-bottom: 1px solid #d8cec7;
			margin-bottom: -1px;
		}
	}

	& + & {
		@include breakpoint(large) {
			border-radius: rem-calc(0);
			border-top: 1px solid #d8cec7;
		}
	}

	&__box {
		@include breakpoint(xmedium) {
			@include xy-grid;
			//@include flex;
			@include flex-align(justify, middle);

		}
	}

	&__left {
		//@include xy-cell(12, false, 0);

		@include breakpoint(large) {
			@include xy-cell(shrink, false, 0);
		}
	}

	&__right {
		@include xy-cell(12, false, 0);
		//@include flex-align-self(bottom);
		//height: 100%;A

		@include breakpoint(large) {
			@include xy-grid;
			@include xy-cell(auto, $gutter-position: left);
			@include flex;
			@include flex-align(justify);
		}
	}

	&__image-box {
		// @include xy-cell(3, false, 0);
		position: relative;
		background: #d8cec7;
		padding: rem-calc(4);
		border-radius: rem-calc(7);
		// margin-bottom: rem-calc(20);

		@include breakpoint(large) {
			@include xy-cell(shrink, false, 0);
		}
	}

	&__image-link {
		overflow: hidden;
		display: block;
		position: relative;
		border-radius: rem-calc(5);

		&::before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: '';
			// border: 1px solid red;
			border-radius: rem-calc(5);
			box-shadow: inset 0 0 3px 3px rgba($black, 0.3);
		}

	}

	&__image {
		border-radius: rem-calc(5);

		@include breakpoint(large) {
			width: rem-calc(90);
		}

	}

	&__labels {
		//@include xy-grid;
		position: absolute;
		top: rem-calc(10);
		left: rem-calc(11);
		//padding-bottom: rem-calc(8);

		@include breakpoint(xxsmall) {
			top: rem-calc(13);
			left: rem-calc(14);
		}

		@include breakpoint(xmedium) {
			left: rem-calc(114);
			top: 0;
			// @include xy-cell(12, false, 0);
		}
	}

	&__image-link {
		display: block;
	}

	&__title-box {
		//@include xy-cell(auto, false, 0);

		//vertical-align: middle;
		margin-top: rem-calc(7);
		margin-bottom: rem-calc(7);

		@include breakpoint(xmedium) {
			@include flex;
			@include flex-align(left, middle);
		}
	}

	&__title {
		// @include xy-cell(auto, false, 0);
		// text-align: center;
		font-size: rem-calc(18);
		line-height: 1.1;
		margin-bottom: 0;

		@include breakpoint(xmedium) {
			font-size: rem-calc(22);
		}
	}

	&__price-box {
		//@include xy-cell(12, false, 0);
		background: #7da744;
		background-image: radial-gradient(ellipse at center, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
		border-radius: rem-calc(5);
		box-shadow: 0 2px 0 darken(#648e37, 15), 0 2px 4px rgba($black, 0.3);
		text-align: center;

		@include breakpoint(large) {
			@include xy-cell(shrink, false, 0);
			width: rem-calc(220);
		}

		@include breakpoint(xmedium) {
			margin-top: 0;

		}
	}

	&__price-box-inner {
		color: $white;
		text-shadow: $brand-text-shadow;
		padding: rem-calc(8 2 3);
		font-family: $header-font-family;
		//@include flex;
		//@include flex-align(center, bottom);


		@include breakpoint(xmedium) {
			padding: rem-calc(16 0 8 20);

		}
	}

	&__price {
		display: inline-block;
		font-size: rem-calc(18);
		line-height: rem-calc(14);


		@include breakpoint(medium) {
			font-size: rem-calc(20);
		}

		@include breakpoint(xmedium) {
			font-size: rem-calc(26);
		}

		span {
			font-size: rem-calc(14);

			@include breakpoint(xmedium) {
				font-size: rem-calc(18);
			}
		}
	}

	&__old-price {
		font-size: rem-calc(14);
		display: inline-block;
		line-height: rem-calc(14);
		text-decoration: line-through;
		padding-right: rem-calc(2);

		@include breakpoint(xmedium) {
			font-size: rem-calc(18);
			padding-right: rem-calc(5);
		}
	}

	&__unit {
		font-size: rem-calc(14);
		line-height: rem-calc(14);


		@include breakpoint(xmedium) {
			font-size: rem-calc(18);
		}
	}
}
