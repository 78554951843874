$block: 'contacts';

.#{$block} {
	&__top {
		@include xy-grid;
		@include xy-gutters($negative: true);

	}

	&__section {
		@include xy-cell(full);
		background: $color-primary;
		box-shadow: $brand-green-box-shadow;
		// border: 1px solid $color-secondary-darker;
		border-radius: rem-calc(20);
		padding: rem-calc(7);

		@include breakpoint(xxmedium) {
			@include xy-cell(4);
		}

		& + & {
			margin-top: rem-calc(10);

			@include breakpoint(medium) {
				margin-top: rem-calc(15);
			}

			@include breakpoint(xxmedium) {
				margin-top: 0;
			}
		}
	}

	&__section-inner {
		background: $color-secondary-lighter;
		background-image: url("https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(17);
		padding: rem-calc(30 15);
		box-shadow: $brand-green-box-shadow;
	}

	&__section-title {
		text-align: center;
		font-family: $header-font-family;
		font-size: rem-calc(20);
		color: $color-primary-darker;
		padding-bottom: rem-calc(20);

		@include breakpoint(medium) {
			font-size: rem-calc(22);
		}

		span {
			//border-bottom: 1px solid $color-secondary-darker;
			position: relative;

			&::before {
				position: absolute;
				width: 100%;
				height: 2px;
				border-radius: 2px;
				background-color: $color-primary-darker;
				//box-shadow: $brand-text-shadow;
				content: '';
				bottom: rem-calc(-5);
			}
		}
	}
	
	&__section-content {
		text-align: center;
		font-size: rem-calc(20);
		font-family: $header-font-family;
		color: $color-primary-darker;

		@include breakpoint(medium) {
			font-size: rem-calc(22);
		}
	}
	
	&__link {
		display: block;
		color: $color-primary-darker;

		@include on-event {
			color: $color-primary-darker;
		}

		span {
			border-bottom: 1px solid transparent;
			transition: all 300ms ease-in-out;
			@include on-event {
				border-bottom-color: $color-primary;
			}
		}
	}

	&__map {
		background: $color-primary;
		border-radius: rem-calc(20);
		padding: rem-calc(7);
		margin: rem-calc(10 5 0);
		box-shadow: $brand-green-box-shadow;

		@include breakpoint(medium) {
			margin: rem-calc(15 0 0);
		}
		@include breakpoint(xxmedium) {
			margin: rem-calc(30 0 0);
		}
	}

	&__map-box {
		background: $color-secondary-lighter;
		background-image: url("https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(17);
		padding: rem-calc(10);
		box-shadow: $brand-green-box-shadow;
	}

	&__map-holder {
		height: rem-calc(300);
		border-radius: rem-calc(7);
		overflow: hidden;

		@include breakpoint(xmedium) {
			height: rem-calc(400);
		}

		@include breakpoint(large) {
			height: rem-calc(500);
		}
	}
}
