$block: 'logo';

.#{$block} {
	position: relative;
	width: rem-calc(140);
	// height: rem-calc(22);
	height: 100%;

	@include breakpoint(xmedium) {
		width: rem-calc(150);

	}

	@include breakpoint(xxmedium) {
		width: rem-calc(175);

	}

	@include breakpoint(large) {
		width: rem-calc(250);

	}

	&__link {
		display: block;
	}

	&__image {
		// position: absolute;
		// top: rem-calc(-60);
		@include vertical-center;
		// width: rem-calc(187);
		// height: rem-calc(160);
	}
}
