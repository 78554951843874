.align_left {
	float: left;
	margin: 0 1em 0.25em 0;
}

.align_right {
	float: right;
	margin: 0 0 0.25em 1em;
}

.align_center {
	display: block;
	margin-right: auto;
	margin-left: auto;
}
