$block: 'c-download-catalog';

.#{$block} {
	//height: 100%;
	background: $color-primary;
	//z-index: 2;
	position: relative;
	// border: 1px solid $color-secondary-darker;
	border-radius: rem-calc(20);
	padding: rem-calc(7);
	//background-image: radial-gradient(ellipse at center, transparent 60%, rgba(84, 113, 46, 0.8) 100%);
	box-shadow: $brand-green-box-shadow;

	&__inner {
		@include xy-grid-container($padding: 0);
		padding: rem-calc(10);
		background: $color-secondary-lighter;
		background-image: url("https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
		border-radius: rem-calc(17);
		box-shadow: $brand-green-box-shadow;
	}

	&__title {
		font-size: rem-calc(22);
		font-family: $header-font-family;
		color: $color-primary-darker;
		//text-shadow: $brand-text-shadow;
		text-align: center;
		margin-bottom: rem-calc(10);

		@include breakpoint(medium) {
			font-size: rem-calc(30);
		}
	}

	&__box {
		@include xy-grid
		@include xy-gutters($negative: true);
		margin-bottom: rem-calc(-15);
	}

	&__column {
		@include xy-cell(full);

		@include breakpoint(medium) {
			@include xy-cell(6);
		}

		&--main {
			@include xy-cell(full);
			padding-bottom: rem-calc(15);

			@include breakpoint(medium) {
				@include xy-cell(full);
				//padding-bottom: rem-calc(30);
			}
		}

		& + & {
			padding-bottom: rem-calc(15);

			@include breakpoint(medium) {
				//padding-bottom: rem-calc(30);
			}
		}
	}

	&__link {
		display: block;
		color: $white;
		font-family: $header-font-family;
		background: $color-primary;
		z-index: 2;
		position: relative;
		// border: 1px solid $color-secondary-darker;
		border-radius: rem-calc(10);
		padding: rem-calc(7 15 7 75);
		//background-image: radial-gradient(ellipse at center, transparent 60%, rgba(84, 113, 46, 0.8) 100%);
		box-shadow: $brand-green-box-shadow;
		text-shadow: $brand-text-shadow;
		background: #7da744;
		background-image: radial-gradient(ellipse  at top, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
		//border-radius: rem-calc(15);
		//padding: rem-calc(0 15);
		transition: all 300ms ease-in-out;
		box-shadow: 0 2px 0 darken(#648e37, 15), 0 2px 4px rgba($black, 0.3), 0 0 4px 3px rgba(5,5,5,.1), 0 1px 3px rgba(30, 50, 70, 0.3), inset 0 0 1px rgba(255, 255, 255, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.12);


		@include on-event {
			color: $white;
			background: darken(#7da744, 2);
			background-image: radial-gradient(ellipse  at top, transparent 60%, rgba($color-primary-darker, 0.8) 100%);
		}

		&::before {
			content: '';
			background: red;
			position: absolute;
			top: 0;
			left: 0;
			width: rem-calc(60);
			border-radius: rem-calc(10 0 0 10);
			background: $color-secondary-lighter;
			background-image: url("https://demo.yootheme.com/themes/joomla/2014/everest/templates/yoo_everest/less/uikit/images/default/panel-box-noise-bg.png"), radial-gradient(ellipse at center, transparent 60%, rgba(darken($color-secondary-darker, 3), 1) 100%);
			//border-radius: rem-calc(17);
			//box-shadow: $brand-green-box-shadow;
			box-shadow: 0 2px 0 darken(#a77a14, 0), 0 2px 4px rgba($black, 0.3);
			background: $color-orange;
			// background: #7da744;
			background-image: radial-gradient(ellipse at top, transparent 60%, rgba($color-orange-darker, 0.8) 100%);
			bottom: 0;
		}

		&::after {
			@include icon($icon-menu);
			@include vertical-center;
			color: $white;
			left: rem-calc(15);
			font-size: rem-calc(30);
		}
	}

	&__link-title {
		display: block;
		font-size: rem-calc(20);
	}

	&__link-description {
		display: block;
		line-height: 1;
	}
}
